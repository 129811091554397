<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Klantenservice</h1>
        <p>Vanuit de klantenservice staan we graag voor u klaar met technische vragen over hosting. U kunt inloggen op het <a href="https://cp.hostingondemand.nl/">Klantenpaneel</a> om zelf een aantal belangrijke zaken te regelen of de antwoorden op de veel gestelde vragen doornemen. Wellicht dat u op die manier snel verder kunt. Komt u er zelf niet uit? Dan kunt u contact met ons opnemen, wij zorgen ervoor dat we zo snel mogelijk antwoorden of voor u in actie komen.</p>
        <h3>Contact opnemen met technische vragen</h3>
        <p>De specialisten van Hosting on Demand hebben veel ervaring met de verschillende technische mogelijkheden, net als met de eventuele problemen waar u tegenaan kunt lopen. We adviseren u graag over de verschillende pakketten voor webhosting, zakelijke hosting en bijvoorbeeld een Virtual Private Server. We kunnen u de verschillen uitleggen en op basis van uw specifieke behoeften aangeven wat er het best geschikt is.</p>
        <p>Meer specifieke technische vragen, bijvoorbeeld binnen een pakket of op basis van toepassingen of mogelijkheden? Ook daar zijn onze specialisten in hosting u graag mee van dienst. We lossen problemen voor u op of zorgen voor aanvullende mogelijkheden om u volledig naar wens van dienst te kunnen zijn.</p>
        <h3>Inloggen bij Mijn Hosting on Demand</h3>
        <p>Een aantal zaken kunt u gewoon zelf online regelen, door in te loggen op het <a href="https://cp.hostingondemand.nl/">Klantenpaneel</a>. Zodra u klant wordt kunt u uw eigen account aanmaken, om de belangrijkste gegevens in te zien en te kunnen beheren. Maak wijzigingen of verander te instellingen, afhankelijk van waar u op dat moment gebruik van wilt maken. We zorgen er met de online omgeving voor dat u onze klantenservice zo min mogelijk nodig zal hebben, dankzij een gebruiksvriendelijke omgeving waarin u zelf de touwtjes in handen heeft.</p>
        <p><i>“Fijn dat ik allerlei zaken gewoon zelf online kan regelen. Heel gebruiksvriendelijk en wel zo snel, ik kon meteen weer verder”</i>, aldus een tevreden gebruiker.</p>
        <h3>Antwoord op veel gestelde vragen</h3>
        <p>Op veel van de technische vragen over onze diensten geven we antwoord in de <router-link to="/klantenservice/veel-gestelde-vragen/">veel gestelde vragen</router-link>. We zorgen er met die antwoorden op de vragen voor dat u wellicht meteen verder kunt, zodat u niet op een antwoord van onze technisch specialisten hoeft te wachten. Bekijk de veel gestelde vragen over webhosting, zakelijke hosting, e-mail en bijvoorbeeld de Virtual Private Server.</p>
        <h3>Neem contact met ons op</h3>
        <p>Komt u er zelf helaas niet uit of heeft u een specifieke vraag voor onze specialisten in hosting? Neem contact op via het onderstaande formulier. Vul uw naam, telefoonnummer, e-mailadres en uw vraag in, dan nemen we zo snel mogelijk contact met u op.</p>
        <p style="padding-left: 15px;">support@hostingondemand.nl</p>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';

export default {
  name: 'CustomerService',
  components: {
    Breadcrumbs,
  },
}
</script>